<template>
  <div class="wrapper">
    <div class="background">
      <img src="@/assets/bg.png" alt="Background" />
    </div>

    <div class="buttonWrapper" @click="openLink1">
      <img class="arrow" src="@/assets/arrow.png" alt="Arrow" />
      <div class="buttonLink">
        <img class="button" src="@/assets/button.png" alt="Button" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {  onMounted } from "vue";
const openLink1 = () => {
  window.location.href = "https://chat.whatsapp.com/DI5FlxuV48G7kMAAY4l4wj";
  window?.ttq && window?.ttq.track('ClickButton', {});
  console.log("点击按钮",window?.ttq)
  // eslint-disable-next-line 

};

onMounted(() => {

});
</script>
<style lang="less" scoped>
* {
  user-select: none;
}

.wrapper {
  width: 100%;
  position: relative;

  .buttonWrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    cursor: pointer;

    .arrow {
      transition: all 2s;
      animation: arrowZoomInOutAndMove 2s infinite linear;
    }

    .buttonLink {
      display: block;
      animation: buttonZoomInOut 2s infinite;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

@keyframes buttonZoomInOut {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}

@keyframes arrowZoomInOutAndMove {
  0%,
  100% {
    transform: scale(1) translateY(-50px);
  }
  25% {
    transform: scale(1.1) translateY(-50px);
  }
  50% {
    transform: scale(1.3) translateY(-50px);
  }
  75% {
    transform: scale(0.9) translateY(-20px);
  }
}
</style>
